/** @jsx jsx */
import { jsx } from 'theme-ui';
import React from 'react';
import { Layout, StaticSEO, StrapiSectionListContainer } from '@core';
import { ContactSection } from '@composite';
import { DataPrivacyConfig } from 'src/store/DataPrivacyContext';
import { useLocale, useTranslate } from '@modules';

export default () => {
  const ref = React.useRef(null);
  const __ = useTranslate();
  const locale = useLocale();

  return (
    <Layout>
      <DataPrivacyConfig path={`${locale}/data-privacy-quiz`} text={__('navbar.dataprivacy')} />

      <StaticSEO pageId="data-privacy-quiz" />

      <StrapiSectionListContainer pageId="data-privacy-quiz" ref={ref} />

      <ContactSection id="landing.contact" triggerRef={() => ref} />

    </Layout>
  );
};
